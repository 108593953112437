import CreditConfiguration from '@/router/view/credit-configuration/CreditConfiguration'
const Index = () =>
  import(/* webpackChunkName: "DataStatistics" */ '@views/creditConfiguration/Index')

const scriptRouter = [
  {
    path: 'credit-configuration',
    component: Index,
    children: [...CreditConfiguration]
  }
]

export default scriptRouter
