<template>
  <div v-if="nonData" class="full-width">
    <app-loading v-if="isLoading"/>
    <template v-else>
      <slot name="empty"/>
      <a-empty v-if="!$slots.empty || !$slots.empty.length"/>
    </template>
  </div>
</template>

<script>
export default {
  name: 'LoadingStatus',
  props: {
    nonData: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },
};
</script>
