import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/RequestNoLimits';
import { downloadFileExtra } from '@/utils/download';

// 参训率-月度变化趋势
export function getParticipationMonthlyTrend (params) {
  return request({
    url: `/datacenter/admin/participationrate/participationRateStatistical`,
    method: 'get',
    params,
  });
}

// 参训率-月度变化趋势 - 导出表格
export function getParticipationMonthlyTrendDownload (params) {
  let url = '/datacenter/admin/participationrate/participationRateStatistical';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

//参训率-岗位查询对比分析
export function getRatioParsing (params) {
  let url = '/datacenter/admin/participationrate/ratioParsing';
  return request({
    url: url,
    method: 'get',
    params,
  });
}

//参训率-岗位查询对比分析 --导出excel
export function excelRatioParsing (params) {
  let url = '/datacenter/admin/participationrate/ratioParsing';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  params.is_download = 1;
  return downloadFileExtra({
    url: path,
    method: 'get',
    params,
    token: store.state.User.token || '',
  });
}

//参训率-岗位查询
export function getPostGroupParticipat (params) {
  let url = '/datacenter/admin/participationrate/postGroupParticipat';
  return request({
    url: url,
    method: 'get',
    params,
  });
}
//参训率-岗位查询对比分析 --导出excel
export function excelPostGroupParticipat (params) {
  let url = '/datacenter/admin/participationrate/postGroupParticipat';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  params.is_download = 1;
  return downloadFileExtra({
    url: path,
    method: 'get',
    params,
    token: store.state.User.token || '',
  });
}

//参训率-岗位查询
export function getPieChart (params) {
  let url = '/datacenter/admin/participationrate/pieChart';
  return request({
    url: url,
    method: 'get',
    params,
  });
}