const Index = () => import(/* webpackChunkName: "Account" */  '@/account/Index.vue');

const Login = () => import(/* webpackChunkName: "Account" */  '@/account/Login.vue');
const ResetPassword = () => import(/* webpackChunkName: "Account" */  '@/account/ResetPassword.vue');

const route = {
  path: '/account',
  component: Index,
  children: [
    { path: '', redirect: 'login' },
    { path: 'login', component: Login },
    { path: 'reset', component: ResetPassword },
  ],
};

export default route;
