const Index = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/Index.vue');
const RouterView = () => import(/* webpackChunkName: "speechcraft" */  '@/views/routerView/Index.vue');

const List = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/List.vue');

const Detail = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/detail/Detail.vue');
const Config = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/detail/Config.vue');
const Keyword = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/detail/Keyword.vue');
const Practice = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/detail/Practice.vue');
const Verification = () => import(/* webpackChunkName: "speechcraft" */ '@/views/speechcraft/skill/detail/Verification.vue');

const Category = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/Category.vue');
const Synonym = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/Synonym.vue');
const BadWords = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/skill/BadWords.vue');

const detailRoutes = [
  {
    path: '',
    redirect: 'config',
  },
  {
    path: 'config',
    component: Config,
  },
  {
    path: 'keyword',
    component: Keyword,
  },
  {
    path: 'practice',
    component: Practice,
  },
  {
    path: 'verification',
    component: Verification,
  },
];

const routes = [
  {
    path: 'skill',
    component: Index,
    meta: { title: '话术管理' },
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: List,
        meta: { title: '话术列表' },
      },
      {
        path: 'create',
        component: Detail,
        meta: { title: '添加话术' },
        children: [...detailRoutes],
      },
      {
        path: 'edit',
        component: Detail,
        meta: { title: '话术编辑' },
        children: [...detailRoutes],
      },
      {
        path: 'detail',
        component: Detail,
        meta: { title: '话术详情' },
        children: [...detailRoutes],
      },
      {
        path: 'category',
        component: RouterView,
        meta: { title: '话术分类管理' },
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: Category },
        ],
      },
      {
        path: 'synonym',
        component: RouterView,
        meta: { title: '同义词管理' },
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: Synonym },
        ],
      },
      {
        path: 'badWords',
        component: RouterView,
        meta: { title: '违规词管理' },
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: BadWords },
        ],
      },
    ],
  },
];

export default routes;
