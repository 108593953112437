import request from '../utils/Request';
import requestNoLimits from '../utils/RequestNoLimits';

// 话术-选择下拉-话术状态
export function getScriptStatus () {
  return request({
    url: '/speechskill/admin/select/skill-status',
    method: 'get',
  });
}

// 公共-岗位信息
export function getJobs () {
  return request({
    url: '/manage/admin/select/jobs',
    method: 'get',
  });
}

// 公共-机构信息树形结构
export function getOrgTree () {
  return request({
    url: '/manage/admin/select/orgtree',
    method: 'get',
  });
}
// 公共-所有大区 id
export function getBigAreaIds () {
  return request({
    url: '/datacenter/admin/getBigArea',
    method: 'get',
  });
}
// 公共-机构信息树形结构
export function getStoreType () {
  return request({
    url: '/storeType',
    method: 'get',
  });
}
// 公共-机构信息树形结构--数据中心
export function orgtreeByUser (params) {
  return request({
    url: '/manage/admin/select/orgtree',
    method: 'get',
    params
  });
}

// 管理员信息
export function getUserInfo () {
  return request({
    url: '/manage/admin/manage/info',
    method: 'get',
  });
}

// 导航链接
export function getLinks () {
  return request({
    url: '/manage/admin/nav/url',
    method: 'get',
  });
}

// 后台导航 (老接口)
// export function getMenu () {
//   return request({
//     url: '/manage/admin/menu',
//     method: 'get',
//   });
// }
// 后台导航以及子导航 (new)
export function getMenu () {
  return request({
    url: '/manage/admin/rolemenu',
    method: 'get',
  });
}

// 获取权限列表 (new)
export function getPermissionList () {
  return request({
    url: '/manage/admin/rolehaspermission',
    method: 'get',
  });
}

// 获取 OSS 信息
export function getOSSInfo (data) {
  return request({
    url: '/index/oss/get',
    method: 'post',
    data
  });
}

// 上传图片
export function uploadPic (data) {
  return request({
    url: '/Admin/Overt/upload_pic.html',
    method: 'post',
    data
  });
}

// 搜索框-培训标题
export function getOfflineTaskList (params) {
  return request({
    url: `/public/conf/offline/searchtitle`,
    method: 'get',
    params,
  });
}

// 搜索框-任务标题
export function getOnlineTaskList (params) {
  return request({
    url: `/public/conf/plan/searchtitle`,
    method: 'get',
    params,
  });
}

// 上传文件
export function uploadFileToPublic (data) {
  return requestNoLimits({
    url: '/public/conf/upload',
    method: 'post',
    data
  });
}

// 获取用户明文信息
export function getUserPlaintext (params) {
  return request({
    url: `/datacenter/admin/getUserInfo`,
    method: 'get',
    params,
  });
}


