import bannerRoutes from './banner';
import stickerRoutes from './sticker';

const Index = () => import(/* webpackChunkName: "Advertising" */  '@views/advertising/Index.vue');
const RouterView = () => import(/* webpackChunkName: "Advertising" */  '@views/routerView/Index.vue');

const routes = [
  {
    path: 'advertising',
    component: Index,
    meta: { title: '内容管理' },
    children: [
      // to be removed
      { path: '', redirect: 'banner' },
      ...bannerRoutes,
      ...stickerRoutes,

      // new routes
      {
        path: 'content',
        component: RouterView,
        children: [
          ...bannerRoutes,
          ...stickerRoutes,
        ],
      },
    ],
  },
];

export default routes;
