const BigArea = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/bigArea/Index');
const Exam = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/bigArea/Exam');
const Finish = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/bigArea/Finish');
const Participate = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/bigArea/Participate');

const StatisticsParticipationBigAreaRouter = [
  {
    path: 'bigArea',
    component: BigArea,
    children: [
      {
        path: '',
        redirect: 'finish',
      },
      {
        name: 'StatisticsParticipationBigAreaFinish',
        path: 'finish',
        component: Finish,
      },
      {
        name: 'StatisticsParticipationBigAreaExam',
        path: 'exam',
        component: Exam,
      },
      {
        name: 'StatisticsParticipationBigAreaParticipate',
        path: 'participate',
        component: Participate,
      },
    ],
  },
];

export default StatisticsParticipationBigAreaRouter;
