import Index from '@views/Index';

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./', true, /\.js$/);
const modules = [];

modulesFiles.keys().filter(dir => (
  (dir !== './Index.js' && dir.indexOf('Index.js') > -1) ||
  (dir !== './index.js' && dir.indexOf('index.js') > -1)
)).reduce((ms, modulePath) => {
  const module = modulesFiles(modulePath).default;
  modules.push(...module);
  return module;
}, {});

const route = {
  path: '/index',
  component: Index,
  children: [
    { path: '', redirect: 'speechSkill' },
    ...modules,
  ],
};

export default route;
