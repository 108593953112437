import {
  getTagCourses,
} from '@/views/dataCenter/api';

const DataCenter = {
  namespaced: true,
  state: {
    tagCoursesLoading: false,
    tagCourses: [],
  },
  mutations: {
    updateTagCourseStatus(state, loading) {
      state.tagCoursesLoading = !!loading;
    },
    updateTagCourses(state, list) {
      state.tagCourses = list || [];
    },
  },
  actions: {
    async getTagCourses({ commit, state }) {
      if (state.tagCoursesLoading) {
        return;
      }
      commit('updateTagCourseStatus', true);

      const data = await getTagCourses();
      if (!data || data.error_code) {
        return [];
      }
      const result = data?.data || [];
      commit('updateTagCourses', result);
      commit('updateTagCourseStatus', false);
      return result;
    },

  },
};

export default DataCenter;
