import FullContentViewer from '@/components/viewer/FullContentViewer.vue';
import Pagination, {
  getPagination,
  updatePagination,
} from '@/components/table/Pagination.vue';

const table = {
  components: {
    FullContentViewer,
    Pagination,
  },
  computed: {
    columns() {
      return [...this.originColumns];
    },
  },
  watch: {
    columns: {
      immediate: true,
      handler() {
        this.$nextTick(() => this.fixTableBlankColumn());
      },
    },
  },
  data() {
    return {
      fetching: false,
      downloading: false,
      saving: false,
      fixColumnOffset: 0,
      defaultForm: {},

      form: { ...this.defaultForm },
      originColumns: [],
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.form = { ...this.defaultForm };
  },
  mounted() {
    window.addEventListener('resize', this.fixTableResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.fixTableResize);
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.pageSize);
    },
    onReset(fetch = true) {
      this.form = { ...this.defaultForm };
      if (fetch) {
        this.onSearch();
      } else {
        this.data = [];
        this.pagination = getPagination();
      }
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    onRefreshPage() {
      this.onSearch(
        this.pagination.current,
        this.pagination.pageSize,
      );
    },
    updatePagination(pagination) {
      this.pagination = updatePagination(this.pagination, pagination);
    },

    async getData() {
    },
    async executeGetData(getData) {
      if (this.fetching) {
        return Promise.reject(new Error('Loading'));
      }
      this.fetching = true;

      try {
        return getData().then((data) => {
          this.updatePagination(data?.meta?.pagination || {});
        }).catch(() => {
          this.fetching = false;
        }).finally(() => {
          this.fetching = false;
        });
      } catch (e) {
        this.fetching = false;
        return Promise.reject(e);
      }
    },

    exportExcel() {
    },
    onExportExcel() {
    },
    executeExportExcel(exportExcel) {
      if (this.downloading) {
        return Promise.reject(new Error('Loading'));
      }
      this.downloading = true;

      try {
        return exportExcel().then(() => {
          this.$message.success('导出成功');
        }).catch((data) => {
          this.$message.error(data?.message || '导出失败');
          this.downloading = false;
        }).finally(() => {
          this.downloading = false;
        });
      } catch (e) {
        this.$message.error('导出失败');
        this.downloading = false;
        return Promise.reject(e);
      }
    },

    fixTableBlankColumn() {
      if (!this.$refs?.tableRef) {
        return;
      }
      const table = this.$refs.tableRef.$el;
      const colgroup = table.querySelector('colgroup');
      const cols = colgroup?.querySelectorAll('col') || [];

      const fixedColIndex = this.columns.findIndex((item) => item.autoExpand);
      if (fixedColIndex === -1) {
        return;
      }
      [].forEach.call(cols, (col, index) => {
        const offsetIndex = index - this.fixColumnOffset;
        if (offsetIndex < 0) {
          this.setStyle(col, { width: col.style.minWidth || 'auto' });
          return;
        }
        if (fixedColIndex === offsetIndex) {
          this.setStyle(col, { width: 'auto' });
        } else {
          this.setStyle(
            col,
            {
              width: this.columns[offsetIndex].width || col.style.minWidth || 'auto',
            },
          );
        }
      });

      this.fixTableBlankColumnFirefox();
    },
    fixTableBlankColumnFirefox(width) {
      // if ([
      //   'firefox',
      //   'qqbrowser',// qq 浏览器
      //   'se 2.x', // 搜狗浏览器
      //   '', // 360 浏览器 ???
      // ].every(
      //   (item) => navigator?.userAgent?.toLowerCase().indexOf(item) === -1,
      // )) {
      //   return;
      // }

      if (!this.$refs?.tableRef) {
        return;
      }
      const table = this.$refs.tableRef.$el;
      const scrollTable = table.querySelector('.ant-table-scroll');
      const th = scrollTable.querySelectorAll('th');

      const fixedColIndex = this.columns.findIndex((item) => item.autoExpand);
      if (fixedColIndex === -1) {
        return;
      }

      [th].forEach((item) => {
        this.setStyle(
          item[fixedColIndex + this.fixColumnOffset],
          {
            width: width || this.columns[fixedColIndex].width || 'auto',
          },
        );
      });
    },
    fixTableResize() {
      if (!this.$refs?.tableRef) {
        return;
      }
      const autoExpand = this.columns.filter((i) => i.autoExpand);

      const width = this.$refs.tableRef.$el.scrollWidth;
      const columnsWidth = this.columns.reduce(
        (prev, cur) => cur.autoExpand ? prev : parseInt(cur.width, 10) + prev,
        0,
      ) + this.getOffsetWidth();
      const autoExpandWidth = autoExpand.reduce(
        (prev, cur) => parseInt(cur.width, 10) + prev,
        0,
      );

      if (columnsWidth + autoExpandWidth > width) {
        this.fixTableBlankColumnFirefox();
      } else {
        this.fixTableBlankColumnFirefox(
          (width - columnsWidth) / autoExpand.length + 'px',
        );
      }
    },
    getOffsetWidth() {
      if (!this.$refs.tableRef?.$el) {
        return 0;
      }

      const table = this.$refs.tableRef.$el;
      const scrollTable = table.querySelector('.ant-table-scroll');
      const th = scrollTable.querySelectorAll('th');

      if (this.fixColumnOffset < 1) {
        return 0;
      }
      return new Array(this.fixColumnOffset).fill(0).map(
        (item, index) => parseFloat(this.getStyle(th[index], 'width'), 10) || 0,
      ).reduce((prev, cur) => prev + cur, 0);
    },
    setStyle(el, styles = {}) {
      if (!el.style) {
        el.style = {};
      }
      Object.keys(styles).forEach((key) => {
        el.style[key] = styles[key];
      });
    },
    getStyle(el, property) {
      if (el.currentStyle) {
        return el.currentStyle[property];
      } else if (window.getComputedStyle) {
        return window.getComputedStyle(el, null)[property];
      }
    },
  },
};

export default table;
