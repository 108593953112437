const RouterView = () => import(/* webpackChunkName: "Statistics" */ '@views/routerView/Index.vue');

const Detail = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Detail.vue');
const Area = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Area.vue');
const Distributor = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Distributor.vue');
const Student = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/Student.vue');

const StudentData = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Group/StudentDetail.vue');

const routes = [
  {
    path: 'group',
    component: RouterView,
    children: [
      {
        path: '',
        component: Detail,
        children: [
          { path: '', redirect: 'area' },
          { path: 'area', component: Area },
          { path: 'distributor', component: Distributor },
          { path: 'student', component: Student },
          { path: 'student', component: Student },
        ],
      },
      { path: 'studentDetail', component: StudentData },
    ],
  },
];

export default routes;
