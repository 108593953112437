const RouterView = () => import(/* webpackChunkName: "integral" */  '@views/routerView/Index.vue')

const Index = () => import(/* webpackChunkName: "integral" */  '@views/integral/Index.vue')

const IntegralRule = () => import(/* webpackChunkName: "integral" */  '@views/integral/integral/Rule.vue')
const IntegralRuleList = () => import(/* webpackChunkName: "integral" */  '@views/integral/integral/List.vue')
const IntegralRuleCreate = () => import(/* webpackChunkName: "integral" */  '@views/integral/integral/Create.vue')


const routes = [
  {
    path: 'integral',
    component: Index,
    meta: { title: '积分配置' },
    children: [
      { path: '', redirect: 'integral' },
      {
        path: 'integral',
        meta: { title: '积分管理' },
        component: RouterView,
        children: [
          { path: '', redirect: 'rule' },
          {
            path: 'rule',
            component: IntegralRule,
            children: [
              { path: '', component: IntegralRuleList, meta: { title: '积分规则' } },
              { path: 'detail', component: IntegralRuleCreate, meta: { title: '积分规则详情' } },
              { path: 'create', component: IntegralRuleCreate, meta: { title: '新建积分规则' } },
            ]
          }
        ]
      }
    ]
  }
]

export default routes
