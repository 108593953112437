import { getLinks, getUserInfo } from '@/api/Common';

const Token = {
  namespaced: true,
  state: {
    token: '',
    homeUrl: '',
    logOutUrl: '',

    userName: '',
    trueName: '',
    headImg: '',
    isAdmin: 0,
  },
  mutations: {
    updateToken(state, token) {
      state.token = token || '';
    },
    updateHomeUrl(state, url) {
      state.homeUrl = url || '';
    },
    updateLogOutUrl(state, url) {
      state.logOutUrl = url || '';
    },

    updateUserName(state, name) {
      state.userName = name || '';
    },
    updateHeadImg(state, url) {
      state.headImg = url || '';
    },
    updateTrueName(state, name) {
      state.trueName = name || '';
    },
    updateIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
  },
  actions: {
    async getLinks({ commit }) {
      const data = await getLinks();
      if (!data || data.error_codee) {
        return;
      }
      commit('updateHomeUrl', data?.data?.index?.url);
      commit('updateLogOutUrl', data?.data?.logout?.url);
    },

    async getUserInfo({ commit }) {
      const data = await getUserInfo();
      if (!data || data.error_code) {
        return;
      }
      commit('updateUserName', data?.data?.username);
      commit('updateHeadImg', data?.data?.head_img);
      commit('updateTrueName', data?.data?.truename);
      commit('updateIsAdmin', data?.data?.is_admin);
    },
  },
};

export default Token;
