const Index = () => import(/* webpackChunkName: "Survey" */  '@/views/survey/Index.vue');

const PlanetIndex = () => import(/* webpackChunkName: "Survey" */  '@/views/survey/planetGSurvey/Index.vue');
const PlanetList = () => import(/* webpackChunkName: "Survey" */  '@/views/survey/planetGSurvey/List.vue');
const PlanetResult = () => import(/* webpackChunkName: "Survey" */  '@/views/survey/planetGSurvey/Result.vue');
const PlanetRespondent = () => import(/* webpackChunkName: "Survey" */  '@/views/survey/planetGSurvey/Respondent.vue');

const routes = [
  {
    path: 'survey',
    component: Index,
    children: [
      { path: '', redirect: 'planet' },
      {
        path: 'planet',
        component: PlanetIndex,
        meta: { title: '调研问卷' },
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: PlanetList, meta: { title: '问卷列表' } },
          { path: 'result', component: PlanetResult, meta: { title: '作答详情' } },
          { path: 'respondent', component: PlanetRespondent, meta: { title: '个人详情' } },
        ],
      },
    ],
  },
];

export default routes;
