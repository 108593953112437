const BroadcastIndex = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/Index');

// 直播流量统计
const FlowIndex = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/flow/Index');
const FlowOverview = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/flow/Overview');
const FlowDetail = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/flow/Detail');
const FlowBroadcast = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/flow/Broadcast');
const FlowUser = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/flow/User');

// 直播数据查询
const QueryIndex = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/query/Index');
const QueryOverview = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/query/Overview');
// const QueryWatch = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/query/Watch');
const QueryHeat = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/query/Heat');
const QueryHeatDetail = () => import(/* webpackChunkName: "DataStatistics" */  '@views/data-statistics/broadcast/query/HeatDetail');


const scriptRouter = [
  {
    path: 'broadcast-overview',
    redirect: 'broadcast/flow/overview',
  },
  {
    path: 'broadcast-detail',
    redirect: 'broadcast/flow/detail',
  },
  {
    path: 'broadcast',
    component: BroadcastIndex,
    children: [
      { path: '', redirect: 'flow' },
      {
        path: 'flow',
        component: FlowIndex,
        meta: { title: '直播流量统计' },
        children: [
          { path: '', redirect: 'overview' },
          { path: 'overview', component: FlowOverview, meta: { title: '直播数据总览' } },
          {
            path: 'detail', component: FlowDetail, children: [
              { path: '', redirect: 'broadcast' },
              { path: 'broadcast', component: FlowBroadcast, meta: { title: '直播明细' } },
              { path: 'user', component: FlowUser, meta: { title: '人员明细' } },
            ],
          },
        ],
      },
      {
        path: 'query',
        component: QueryIndex,
        meta: { title: '直播数据查询' },
        children: [
          { path: '', redirect: 'overview' },
          {
            path: 'overview', component: QueryOverview,
            children: [
              { path: '', redirect: 'heat' },
              // { path: 'watch', component: QueryWatch, meta: { title: '观看数据' } },
              { path: 'heat', component: QueryHeat, meta: { title: '直播热度数据' } },
            ],
          },
          { path: 'heat-detail', component: QueryHeatDetail, meta: { title: '直播热度明细' } },
        ],
      },
    ],
  },
];

export default scriptRouter;
