const FoundIndex = () => import(/* webpackChunkName: "Substance" */  '@views/substance/found/Index');

const VoteIndex = () => import(/* webpackChunkName: "Substance" */  '@views/substance/found/vote/Index');
const VoteList = () => import(/* webpackChunkName: "Substance" */  '@views/substance/found/vote/List');
const VoteDetail = () => import(/* webpackChunkName: "Substance" */  '@views/substance/found/vote/Detail');
const VoteBasic = () => import(/* webpackChunkName: "Substance" */  '@views/substance/found/vote/detail/Basic');
const VoteContent = () => import(/* webpackChunkName: "Substance" */  '@views/substance/found/vote/detail/Content');

const routes = [
  {
    path: 'found',
    component: FoundIndex,
    children: [
      { path: '', redirect: 'vote' },
      {
        path: 'vote',
        component: VoteIndex,
        children: [
          { path: '', redirect: 'list' },
          { path: 'list', component: VoteList },
          {
            path: 'detail',
            component: VoteDetail,
            children: [
              { path: '', redirect: 'basic' },
              { path: 'basic', component: VoteBasic },
              { path: 'content', component: VoteContent },
            ],
          },
        ],
      },
    ],
  },
];

export default routes;
