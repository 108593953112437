import found from './Found';

const Index = () => import(/* webpackChunkName: "Substance" */  '@views/substance/Index');

const routes = [
  {
    path: 'substance',
    component: Index,
    children: [
      { path: '', redirect: 'found' },
      ...found,
    ],
  },
];

export default routes;
