// banner 配置
const Index = () => import(/* webpackChunkName: "Advertising" */ '@views/advertising/advertising/banner/Index.vue');
const List = () => import(/* webpackChunkName: "Advertising" */ '@views/advertising/advertising/banner/List.vue');
const Detail = () => import(/* webpackChunkName: "Advertising" */ '@views/advertising/advertising/banner/Detail.vue');

const routes = [
  {
    path: 'banner',
    component: Index,
    meta: { title: 'banner 配置' },
    children: [
      { path: '', redirect: 'list' },
      { path: 'list', component: List, meta: { title: 'banner 列表' } },
      { path: 'create', component: Detail, meta: { title: '添加 banner' } },
      { path: 'edit', component: Detail, meta: { title: '编辑 banner' } },
      { path: 'detail', component: Detail, meta: { title: 'banner 详情' } },
    ],
  },
];

export default routes;
