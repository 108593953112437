<template>
  <div class="statistic-audio-result">
    <a-modal :title="modalTitle"
             :visible="modalShown"
             :confirm-loading="confirmLoading"
             :width="800"
             :centered="true"
             :mask="true"
             :mask-closable="true"
             :destroy-on-close="true"
             :after-close="emitClose"
             @cancel="close"
             dialog-class="statistic-audio-result-modal">
      <div class="statistic-audio-result-box">
        <div v-if="url" :id="containerId" class="statistic-audio-box">
          <audio :src="url" class="audio" :id="playerId"></audio>
          <PlayerControlBar :video-container-id="containerId" :video-id="playerId"
                            :hidden="['fullscreen']"
                            class="control-bar"></PlayerControlBar>
        </div>
        <span v-else>暂无音频</span>
      </div>

      <div slot="footer">
        <a-button @click="close">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PlayerControlBar from '@views/statistics/components/PlayerControlBar';
import Common from '@/utils/Common';

export default {
  name: 'StatisticAudioResult',
  components: {PlayerControlBar},
  props: {
    data: {type: Object, default: () => new Object()},
  },
  computed: {
    modalTitle() {
      return '查看音频';
    },
    url() {
      return this.data?.answer_voice_url || '';
    },
  },

  data() {
    return {
      playerId: 'P' + Common.getRandomString(32),
      containerId: 'C' + Common.getRandomString(32),

      modalShown: true,
      confirmLoading: false,
    }
  },

  methods: {
    close() {
      this.modalShown = false;
    },
    emitClose() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped lang="scss">
.statistic-audio-result {

  .ant-modal-body {
    padding: 3px;
  }

  .statistic-audio-result-box {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 120px;
    max-height: 80vh;

    .statistic-audio-box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #000000;
    }

    .audio {
      display: none;
    }

    .control-bar {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
