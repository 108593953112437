<template>
  <div class="app-resizable-block" :class="[mode]" @mousemove="onMouseMove">
    <div ref="startBlockRef" class="item-block start-block">
      <slot name="start"></slot>
    </div>
    <div class="divider" @mousedown="setResizable"></div>
    <div ref="endBlockRef" class="item-block end-block">
      <slot name="end"></slot>
    </div>
  </div>
</template>

<script>
import Common from '@/utils/Common';

export default {
  name: 'ResizableBlock',
  props: {
    mode: {type: String, validator: (mode) => ['vertical', 'horizontal'].includes(mode), default: 'horizontal'},
  },
  data() {
    return {
      resizable: false,
      startSize: 0,
      endSize: 0,

      startBlock: null,
      endBlock: null,

      originMouseX: 0,
      originMouseY: 0,

      modeMap: {
        'vertical': 'height',
        'horizontal': 'width',
      },
    }
  },
  created() {

  },
  mounted() {
    document.addEventListener('mouseup', this.cancelResizable, false);
  },
  beforeDestroy() {
    document.removeEventListener('mouseup', this.cancelResizable);
  },
  methods: {
    setResizable(event) {
      if (event) {
        this.originMouseX = event.clientX;
        this.originMouseY = event.clientY;
      }

      this.startBlock = this.$refs.startBlockRef;
      this.endBlock = this.$refs.endBlockRef;

      this.startSize = this.getElSize(this.startBlock);
      this.endSize = this.getElSize(this.endBlock);
      this.resizable = true;
    },
    cancelResizable() {
      this.resizable = false;
      this.startSize = 0;
      this.endSize = 0;
      this.originMouseX = 0;
      this.originMouseY = 0;

      this.startBlock = null;
      this.endBlock = null;
    },

    onMouseMove(event) {
      const offsetX = this.originMouseX - event.clientX;
      const offsetY = this.originMouseY - event.clientY;

      const offset = this.mode === 'vertical' ? offsetY : offsetX;

      this.setElSize(this.startBlock, this.startSize - offset);
      this.setElSize(this.endBlock, this.endSize + offset);
    },

    getElSize(el) {
      return parseFloat(Common.getStyle(el, this.modeMap[this.mode])) || 0;
    },
    setElSize(el, size) {
      if (!el || !el.style) {
        return;
      }
      const attr = this.modeMap[this.mode];

      el.style[attr] = size + 'px';
      el.style['max-' + attr] = size + 'px';
      el.style['min-' + attr] = size + 'px';
    },

  },
}
</script>

<style scoped lang="scss">
.app-resizable-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  $dividerSize: 8px;

  &.vertical {
    flex-direction: column;

    .divider {
      width: 100%;
      height: $dividerSize;
      margin-top: 1px - $dividerSize / 2;
      margin-bottom: 1px - $dividerSize / 2;
      cursor: ns-resize;
    }
  }

  &.horizontal {
    flex-direction: row;

    .divider {
      width: $dividerSize;
      height: 100%;
      margin-left: 1px - $dividerSize / 2;
      margin-right: 1px - $dividerSize / 2;
      cursor: ew-resize;
    }
  }

  .divider {
    flex: none;
    position: relative;
    z-index: 19;
  }

  .item-block {
    overflow: auto;
    flex: auto;
    width: 100%;
    height: 100%;
  }
}
</style>
