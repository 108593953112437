import {
  getSkillList,
  getSkillDetail,
} from '@/views/speechcraft/skill/api';

const Script = {
  namespaced: true,
  state: {
    readOnly: true,
    scriptId: 0,
    script: {},
    scriptList: [],
  },
  mutations: {
    updateReadOnly(state, readOnly) {
      state.readOnly = !!readOnly;
    },
    updateScriptId(state, scriptId) {
      state.scriptId = scriptId || 0;
    },
    updateScript(state, script) {
      state.script = script || {};
    },
    updateScriptList(state, list) {
      state.scriptList = list;
    },
  },
  actions: {
    async getScript({ commit }, id) {
      if (!id) {
        commit('updateReadOnly', false);
        commit('updateScriptId', null);
        commit('updateScript', {});
        return;
      }

      const data = await getSkillDetail({ id: id });
      if (!data || data.error_code) {
        return {};
      }
      const result = data?.data || {};
      commit('updateReadOnly', result.status === 1);
      commit('updateScriptId', id);
      commit('updateScript', result);
    },
    async getScriptListForSelect({ commit }, { value, cate1, cate2 }) {
      const params = {
        title: value,
        page: 1,
        per_page: 50,
      };
      cate1 && (params.cate_1 = cate1);
      cate2 && (params.cate_2 = cate2);
      return getSkillList(params).then((data) => {
        const result = data?.data || [];
        commit('updateScriptList', result);
      }).catch(() => {
      });
    },
  },
};

export default Script;
