const StatisticsSkillDetail = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Detail');
const StatisticsSkillArea = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Area');
const StatisticsSkillDistributor = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Distributor');
const StatisticsSkillStudent = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Student');
const StatisticsSkillStudentDetail = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/StudentDetail');
const StatisticsSkillTopicList = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/TopicList');

const StatisticsSkillRouter = [
  {
    path: 'skillDetail',
    component: StatisticsSkillDetail,
    children: [
      {
        path: '',
        redirect: 'area',
      },
      {
        name: 'StatisticsSkillArea',
        path: 'area',
        component: StatisticsSkillArea,
      },
      {
        name: 'StatisticsSkillDistributor',
        path: 'distributor',
        component: StatisticsSkillDistributor,
      },
      {
        name: 'StatisticsSkillStudent',
        path: 'student',
        component: StatisticsSkillStudent,
      },
      {
        name: 'StatisticsSkillStudentDetail',
        path: 'studentDetail',
        component: StatisticsSkillStudentDetail,
      },
    ],
  },
  {
    path: 'topicList',
    component: StatisticsSkillTopicList,
  },
];

export default StatisticsSkillRouter;
