import {
  getParticipationPeopleTemplate,
  getParticipationPeopleList,
  // getParticipationRole,
  getParticipationTaskList,
  getParticipationPlanCourse,
} from '@/api/statistics/participation/Index';

const StatisticsParticipation = {
  namespaced: true,
  state: {
    role: 0, // 0，非后台管理员；1，后台管理员
    peopleTemplateUrl: '',
    peopleList: [],
    taskList: [], // 课程、考试列表
    planList: [], // 任务列表

    defaultQuery: {
      peopleListId: undefined, // 人员表选中项 id
      autoJobType: 0,
      planJobIds: '',
      courseJobIds: '',
      planIds: '',
      planList: [],
      courseIds: '',
      orgIds: '',
      orgParams: {
        store_state: 1,
        operation_state: '',
        store_age: '',
        store_type: '',
      },
      jobIds: '',
      courseList: [],
      orgList: [],
      jobList: [],
      endTime: undefined,
      name: '',
    },

    query: {},
  },
  mutations: {
    setQuery(state, query) {
      state.query = Object.assign({}, state.defaultQuery, state.query, query || {});
    },
    resetQuery(state) {
      state.query = Object.assign({}, state.defaultQuery);
    },

    updateRole(state, role) {
      state.role = role;
    },
    updatePeopleTemplateUrl(state, url) {
      state.peopleTemplateUrl = url || '';
    },
    updatePeopleList(state, list) {
      state.peopleList = list || [];
    },
    updateTaskList(state, list) {
      state.taskList = list || [];
    },
    updatePlanList(state, list) {
      state.planList = list || [];
    },
  },
  actions: {
    async getParticipationPlanCourse({ commit }) {
      const data = await getParticipationPlanCourse();
      commit('updatePlanList', data?.data);
    },
    // async getParticipationRol({ commit }) {
    //   const data = await getParticipationRole();
    //   commit('updateRole', data?.data?.is_auth || 0);
    // },
    // async getParticipationTaskList({ commit }, type) { // type: course, exam
    async getParticipationTaskList({ commit }) {
      //   const data = await getParticipationTaskList({ type });
      const data = await getParticipationTaskList();
      commit('updateTaskList', data?.data);
    },
    async getTemplateUrl({ commit }) {
      const data = await getParticipationPeopleTemplate();
      commit('updatePeopleTemplateUrl', data?.data?.user?.url);
    },
    async getParticipationPeopleList({ commit }) {
      const data = await getParticipationPeopleList();
      commit('updatePeopleList', data?.data);
    },
  },
};

export default StatisticsParticipation;
