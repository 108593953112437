<template>
  <div class="full-width align-center pt-10 pb-10">
    <a-spin size="large" tip="加载中..."
            v-bind="$attrs"
            class="app-spin"
            :style="style">
      <a-icon slot="indicator" :type="type" spin/>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    type: { type: String, default: 'loading' },
    zoom: { type: Number, default: 1 },
    color: { type: String, default: '#BFBFBF' },
  },
  computed: {
    style() {
      return {
        zoom: this.zoom,
        color: this.color,
      };
    },
  },
};
</script>

<style scoped lang="less">

</style>
