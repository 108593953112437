const PackageIndex = () => import(/* webpackChunkName: "Course" */ '@/views/course/package/Index.vue');

const PackageList = () => import(/* webpackChunkName: "Course" */ '@/views/course/package/List.vue');
const PackageDetail = () => import(/* webpackChunkName: "Course" */ '@/views/course/package/detail/Index.vue');
const PackageCreate = () => import(/* webpackChunkName: "Course" */ '@/views/course/package/detail/Create.vue');
const PackageCourseList = () => import(/* webpackChunkName: "Course" */ '@/views/course/package/detail/Detail.vue');

const PassConfig = () => import(/* webpackChunkName: "Course" */ '@/views/course/package/PassConfig.vue');

const coursePackageDetailRoutes = [
  {
    path: 'basic',
    component: PackageCreate,
    meta: { title: '基本信息' },
  },
  {
    path: 'course-list',
    redirect: 'courseList',
  },
  {
    path: 'courseList',
    component: PackageCourseList,
    meta: { title: '课程管理' },
  },
];

const routes = [
  {
    path: 'package',
    component: PackageIndex,
    meta: { title: '课程包配置' },
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: PackageList,
        meta: { title: '课程包列表' },
      },
      {
        path: 'create',
        component: PackageDetail,
        meta: { title: '新建课程包' },
        children: [...coursePackageDetailRoutes],
      },
      {
        path: 'detail',
        component: PackageDetail,
        meta: { title: '课程包详情' },
        children: [...coursePackageDetailRoutes],
      },
      {
        path: 'pass-config',
        component: PassConfig,
        meta: { title: '达标配置' },
      },
    ],
  },
];

export default routes;
