const Org = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/org/Index');
const Exam = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/org/Exam');
const Finish = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/org/Finish');
const Participate = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/org/Participate');

const StatisticsParticipationOrgRouter = [
  {
    path: 'org',
    component: Org,
    children: [
      {
        path: '',
        redirect: 'finish',
      },
      {
        name: 'StatisticsParticipationOrgFinish',
        path: 'finish',
        component: Finish,
      },
      {
        name: 'StatisticsParticipationOrgExam',
        path: 'exam',
        component: Exam,
      },
      {
        name: 'StatisticsParticipationOrgParticipate',
        path: 'participate',
        component: Participate,
      },
    ],
  },
];

export default StatisticsParticipationOrgRouter;
