import SkillRoutes from './skill';
import GroupRoutes from './group';

const Index = () => import(/* webpackChunkName: "speechcraft" */  '@/views/speechcraft/Index');

const routes = [
  { path: 'speechSkill', redirect: 'speechcraft/skill' },
  { path: 'speechSkill/skillList', redirect: 'speechcraft/skill' },
  {
    path: 'speechcraft',
    component: Index,
    children: [
      { path: '', redirect: 'skill' },

      ...SkillRoutes,
      ...GroupRoutes,
    ],
  },
];

export default routes;
