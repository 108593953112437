const RouterView = () => import(/* webpackChunkName: "Statistics" */ '@views/routerView/Index.vue');

const Detail = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Detail.vue');
const Area = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Area.vue');
const Distributor = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Distributor.vue');
const Student = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/Student.vue');
const StudentDetail = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/StudentDetail.vue');

const TopicList = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/speechSkill/Skill/TopicList.vue');

const routes = [
  {
    path: 'skill',
    component: RouterView,
    children: [
      {
        path: '',
        component: Detail,
        children: [
          { path: '', redirect: 'area' },
          { path: 'area', component: Area },
          { path: 'distributor', component: Distributor },
          { path: 'student', component: Student },
          { path: 'studentDetail', component: StudentDetail },
        ],
      },
      { path: 'topicList', component: TopicList },
    ],
  },
];

export default routes;
