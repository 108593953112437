import request from '@/utils/Request';
import requestNoLimits from '@/utils/RequestNoLimits';

// // 后台--公共--检验查看选择人员表权限
// export function getParticipationRole() {
//   return request({
//     url: '/participation/admin/public/checkRole',
//     method: 'get',
//   });
// }

// 任务下课程ID
export function getParticipationPlanCourse(params) {
  return request({
    url: '/participation/admin/public/getPlanCourse',
    method: 'get',
    params,
  });
}

// 后台--公共--获取课程/考试列表
export function getParticipationTaskList(params) {
  return request({
    url: '/participation/admin/public/getList',
    method: 'get',
    params,
  });
}

// 后台--公共--获取课程/考试列表
export function deleteParticipationTask(id) {
  return request({
    url: `/participation/admin/userimport/${id}`,
    method: 'delete',
  });
}

// 参训率模板--导入的模板链接
export function getParticipationPeopleTemplate() {
  return request({
    url: '/participation/admin/userimport/template',
    method: 'get',
  });
}

// 参训率模板--模板导入数据
export function uploadParticipationPeopleTemplate(data) {
  return requestNoLimits({
    url: '/participation/admin/userimport/file',
    method: 'post',
    data,
  });
}

// 参训率模板--模板筛选
export function getParticipationPeopleList() {
  return request({
    url: '/participation/admin/userimport/screen',
    method: 'get',
  });
}

export * from './BigArea';
export * from './Org';
export * from './Student';

