<template>
  <div class="app-index-page">
    <div class="app-index-header">
      <Header>
        <HeaderNav slot="menu" @select="selectMenu"></HeaderNav>
      </Header>
    </div>
    <div id="appIndexContent" class="app-index-content" :class="{'no-nav': hideNav}">
      <div v-show="!hideNav" class="app-nav-wrapper">
        <Nav ref="navBar"
             :navs="activeMenu ? activeMenu.children : []"
             class="app-nav"
             @select="selectNav"/>
      </div>

      <div class="app-main-wrapper">
        <Main/>
      </div>
    </div>
  </div>
</template>

<script>
// import Navs from '@/utils/Navs/Index';
import { mapState } from 'vuex';
import Header from './Header.vue';
import HeaderNav from './HeaderNav.vue';
import Nav from './Nav.vue';
import Main from './Main.vue';

export default {
  name: 'Layout',
  components: {
    Header,
    HeaderNav,
    Nav,
    Main,
  },
  computed: {
    // navs() {
    // return this.activeMenu?.navs || [];

    // // 证书管理部分账号隐藏菜单
    // const noPermissionUserName = [
    //   'zhengshuwhk',
    //   'zhengshutd',
    //   'zhengshuldb',
    //   'zhengshulyt',
    //   'zhengshuwza',
    //   'zhengshucxc',
    // ];

    // if (this.activeMenu.id !== 5) {
    //   return this.activeMenu?.navs || [];
    // }
    // return (this.activeMenu?.navs || []).map((nav) => {
    //   if (nav?.children?.length) {
    //     nav.children = nav.children.map((i) => {
    //       if (i.needPermission && this.$store.state.User.userName) {
    //         i.permitted = !noPermissionUserName.includes(this.$store.state.User.userName);
    //       }
    //       return i;
    //     });
    //   }
    //   return nav;
    // });
    // },
    ...mapState({
      menu: state => state.Common.menu,
    }),
    hideNav() {
      return this.$route.matched?.some((route) => route.meta?.hideNav);
    },
  },
  watch: {
    'menu'() {
      this.init();
    },
  },
  data() {
    return {
      loading: false,
      activeMenu: {},
    };
  },
  mounted() {
    if (this.menu.length) {
      this.init();
    }
  },
  beforeRouteEnter(to, from, next) {
    next();
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    init() {
      const path = this.$route?.path || '';
      const [nav] = this.menu.filter(
        (menu) => path.startsWith(this.getModuleBaseRoute(menu.route)),
      );
      this.activeMenu = nav;
    },
    selectNav({ is_jump, jump_url } = {}) {
      this.loading = true;
      if (is_jump === 0) {
        this.$redirect(jump_url);
      } else {
        this.$router.push(jump_url);
      }
      this.loading = false;
    },

    selectMenu(menu) {
      if (!menu?.id || menu?.id === this.activeMenu?.id) {
        return;
      }
      this.loading = true;
      if (menu?.is_jump === 0) {
        this.$redirect(menu?.jump_url);
      } else {
        this.$router.push(menu?.jump_url);
        this.activeMenu = menu || {};
      }
      this.loading = false;
    },

    /**
     * 获取模块的基础路由字符串，格式：/index/statistics/
     * @param route 属于某一模块的路由字符串
     * @returns {string}
     */
    getModuleBaseRoute(route) {
      return `/${
        route.split('/').filter((i) => !!i).slice(0, 2).join('/')
      }/`;
    },
  },
};
</script>

<style scoped lang="scss">
$headerHeightWidthGutter: calc(#{$headerHeight} + 2px);

.app-index-page {
  width: 100%;
  height: 100%;
}

.app-index-header {
  height: $headerHeightWidthGutter;
}

.app-index-content {
  overflow: auto;
  width: calc(100% - 205px);
  height: calc(100% - #{$headerHeightWidthGutter});
  margin-left: 205px;

  &.no-nav {
    width: 100%;
    margin-left: 0;
  }
}

.app-nav-wrapper {
  position: fixed;
  left: 0;
  top: $headerHeightWidthGutter;
  bottom: 0;
  z-index: 6;
  width: 205px;
  background-color: #F4F4F6;
}

.app-nav {
  width: 200px;
}
</style>
