import Vue from 'vue';
import { isProd } from '../Config';

Vue.prototype.$log = (...rest) => {
  console.log(rest);
};

Vue.prototype.$console = {
  warn(...rest) {
    if (!isProd) {
      console.warn('[WARNING]', ...rest);
    }
  },
};

Vue.prototype.$globalId = 0;
Vue.prototype.$getGlobalId = (navigate) => {
  Vue.prototype.$globalId += 1;
  return (navigate ? -1 : 1) * Vue.prototype.$globalId;
};
