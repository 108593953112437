import Vue from 'vue';

Vue.prototype.$redirect = (path) => {
  const origin = window.location.origin;
  if (path.startsWith('http')) {
    window.location.href = path;
    return;
  }
  path = path || '';
  path = path.indexOf('/') === 0 ? path : ('/' + path);
  window.location.href = origin + path;
};
