<template>
  <div class="flex flex-start">
    <a-select v-model="selectedIds"
              :allow-clear="true"
              :mode="mode"
              :show-search="true"
              :disabled="disabled"
              placeholder="请选择大区"
              class="select"
              :filter-option="filterOption"
              @change="select">
      <a-select-option v-for="item of options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
    </a-select>

    <a-button v-if="showSelectAllOrg" class="ml-10" @click="selectAllOrg">选择全部大区</a-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SelectBigArea',
  props: {
    value: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    mode: { type: String, default: 'default', validator: (i) => ['default', 'multiple'].includes(i) },
    showSelectAllOrg: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      options: state => {
        const root = state.Common.orgTree;
        const options = [];

        if (root && root.length) {
          root.forEach(i => i.children?.length ? options.push(...i.children) : '');
        }

        return options;
      },
    }),
  },
  watch: {
    value() {
      this.setDefault();
    },
  },
  data() {
    return {
      modalShown: false,
      selectedData: [],
      selectedIds: '',
    };
  },
  created() {
    this.setDefault();
  },
  methods: {
    filterOption(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) > -1;
    },
    setIds(ids) {
      if (this.mode === 'multiple') {
        this.selectedIds = (ids && ids.length ? ids.split(',') : []).filter((i) => !!i).map((i) => +i);
      } else {
        this.selectedIds = +ids ? +ids : undefined;
      }
    },

    selectAllOrg() {
      // const selected = ['北区', '东区', '南区', '西区', '中区'];
      // this.selectedIds = (this.options || []).filter((i) => selected.some((item) => i.name.indexOf(item) > -1)).map((i) => i.id);

      this.selectedIds = [2076, 2078, 2077, 2075, 2079];
      this.select();
    },

    setDefault() {
      if (!this.value) {
        this.setIds('');
        return;
      }
      this.setIds(this.value);
    },

    select() {
      if (!this.options || !this.options.length) {
        return;
      }

      const data = this.options.filter((op) => {
        if (this.mode === 'multiple') {
          return this.selectedIds.includes(+op.id);
        }
        return +this.selectedIds === +op.id;
      });
      this.$emit(
        'change',
        this.mode === 'multiple' ? this.selectedIds.join(',') : this.selectedIds + '',
        data,
      );
    },

  },
};
</script>

<style scoped lang="scss">
.select {
  min-width: 350px;

  ::v-deep .ant-select-selection {
    overflow-y: auto;
    max-height: 120px;
  }
}
</style>
