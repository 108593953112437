const TrainDetails = () => import(/* webpackChunkName: "TrainingCenter" */ '@views/trainingCenter/offline/detail/trainDetails');
const TrainSign = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainSign');
const TrainCheck = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainCheck');
const TrainCheckDetails = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainCheckDetails');
const TrainExam = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainExam');
const TrainLive = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainLive');
const TrainWork = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainWork');
const TrainWorkDetails = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainWorkDetails');
const TrainAction = () => import(/* webpackChunkName: "TrainingCenter" */ '@views/trainingCenter/offline/detail/trainAction');
const TrainSummary = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/trainSummary');
const TrainSubmitTeacher = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/SubmitTeacher');
const TrainSubmitStudent = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/offline/detail/SubmitStudent');

const trainRouter = [
  {
    path: 'trainDetails',
    component: TrainDetails,
    meta: { title: '培训详情' },
    children: [
      {
        path: '',
        redirect: 'trainSign',
      },
      {
        name: 'trainSign',
        path: 'trainSign',
        component: TrainSign,
      },
      {
        name: 'trainCheck',
        path: 'trainCheck',
        component: TrainCheck,
      },
      {
        name: 'trainExam',
        path: 'trainExam',
        component: TrainExam,
      },
      {
        name: 'trainLive',
        path: 'trainLive',
        component: TrainLive,
      },
      {
        name: 'trainWork',
        path: 'trainWork',
        component: TrainWork,
      },
      {
        name: 'trainAction',
        path: 'trainAction',
        component: TrainAction,
      },
      {
        name: 'trainSummary',
        path: 'trainSummary',
        component: TrainSummary,
      },
      {
        name: 'trainSubmitTeacher',
        path: 'submitTeacher',
        component: TrainSubmitTeacher,
      },
      {
        name: 'trainSubmitStudent',
        path: 'submitStudent',
        component: TrainSubmitStudent,
      },
    ],
  },
  {
    path: 'trainCheckDetails',
    component: TrainCheckDetails,
  },
  {
    path: 'trainWorkDetails',
    component: TrainWorkDetails,
  },
];

export default trainRouter;
