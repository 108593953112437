const RouterView = () => import(/* webpackChunkName: "DOS" */  '@views/routerView/Index.vue');

const Index = () => import(/* webpackChunkName: "DOS" */  '@views/dos/Index.vue');
const List = () => import(/* webpackChunkName: "DOS" */  '@views/dos/List.vue');
const ListChapter = () => import(/* webpackChunkName: "DOS" */  '@views/dos/list/Chapter.vue');
const ListCourse = () => import(/* webpackChunkName: "DOS" */  '@views/dos/list/Course.vue');
const Log = () => import(/* webpackChunkName: "DOS" */  '@views/dos/Log.vue');

const routes = [
  {
    path: 'dos',
    component: Index,
    meta: { title: 'DOS管理' },
    children: [
      { path: '', redirect: 'list' },
      {
        path: 'list',
        component: RouterView,
        meta: { title: '二维码管理' },
        children: [
          { path: '', component: List },
          { path: 'chapter', component: ListChapter, meta: { title: '视频管理(多章节)' } },
          { path: 'course', component: ListCourse, meta: { title: '视频管理(普通)' } },
          { path: 'log', component: Log, meta: { title: '日志' } },
        ],
      },
      { path: 'log', component: Log, meta: { title: '日志' } },
    ],
  },
];

export default routes;
