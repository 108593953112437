<template>
  <div class="app-header-nav">
    <a-menu mode="horizontal" theme="dark"
            :overflowedIndicator="initIndicator"
            :multiple="false"
            v-model="selectedIds"
            class="menu"
            @select="selectMenu">
      <a-menu-item v-for="item of menus" :key="item.id">
        <span>{{ item.name }}</span>
      </a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'HeaderNav',
  computed: {
    ...mapState({
      menus: state => state.Common.menu,
    }),
    selectedId() { // 当前选中 menu 的 id
      return this.selectedIds?.length ? this.selectedIds[0] : 0;
    },
  },
  watch: {
    '$route.path'() {
      this.init();
    },
    menus: {
      immediate: true,
      handler() {
        this.init();
      },
    },
  },
  data() {
    return {
      selectedIds: [],
    };
  },
  created() {
    if (this.menus.length) {
      this.init();
    }
  },
  methods: {
    /**
     *
     * @returns {*[]}
     */
    init() {
      const path = this.$route.path || '';
      const [menu] = this.menus.filter(
        (menu) => path.startsWith(this.getModuleBaseRoute(menu.route)),
      );
      if (!menu?.id) {
        return this.selectedIds = [];
      }
      if (menu.id === this.selectedId) {
        return;
      }
      this.selectedIds = [menu.id];
    },
    initIndicator() {
      return (<div class="caret"><span>更多</span>
        <a-icon type="caret-down"/>
      </div>);
    },
    /**
     * 选择某一 menu，若该 menu 已选中，则不执行选中操作
     * @param key 选中 menu 的 id
     */
    selectMenu({ key }) {
      if (!key || this.selectedId === key) {
        return;
      }
      const [menu] = this.menus.filter(i => i.id === key);
      this.$emit('select', menu);
    },

    /**
     * 获取模块的基础路由字符串，格式：/index/statistics/
     * @param route 属于某一模块的路由字符串
     * @returns {string}
     */
    getModuleBaseRoute(route) {
      return `/${
        route.split('/').filter((i) => !!i).slice(0, 2).join('/')
      }/`;
    },
  },
};
</script>

<style scoped lang="scss">
.app-header-nav {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ::v-deep .menu {
    width: 100%;
    height: 100%;
    line-height: 0;
    background-color: $basic01;
  }

  .ant-menu-item {
    height: 100%;
    line-height: $headerHeight;
    border-right: 1px solid $basic02 !important;

    &.ant-menu-item-selected {
      color: $light01;
      background-color: $basic02;
      cursor: default;
    }
  }

  .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: $basic03;
  }

  .caret {
    height: $headerHeight;
    line-height: $headerHeight;
    color: rgba(255, 255, 255, 0.65);
  }

  .ant-menu-submenu-open .caret {
    color: #FFFFFF;
  }
}
</style>
