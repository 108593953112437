import SkillRoutes from './skill';

const Index = () => import(/* webpackChunkName: "competition" */  '@/views/competition/index.vue');

const routes = [
  {
    path: 'competition',
    component: Index,
    children: [
      { path: '', redirect: 'skillConfig' },

      ...SkillRoutes,
    ],
  },
];

export default routes;
