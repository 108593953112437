<template>
  <div>
    <a-modal v-model="visible"
             :title="title"
             centered
             @cancel="close">
      <div class="full-content">
        <p v-for="(item, index) of content" :key="index">{{ item }}</p>
      </div>

      <template slot="footer">
        <a-button @click="close">关闭</a-button>
      </template>
    </a-modal>

    <div @click="show()">
      <div v-for="(item, index) in slotRows" :key="index">{{ item }}</div>
      <div v-if="content.length > slotRowCount" class="more">{{ moreText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullContentViewer',
  props: {
    title: { type: String, default: '查看内容' },
    content: { type: Array, default: () => [] },
    slotRowCount: { type: Number, default: 4 },
    moreText: { type: String, default: '显示全部...' },
    forceShowModal: { type: Boolean, default: false },
  },
  computed: {
    slotRows() {
      return this.content?.filter((item, index) => index < this.slotRowCount) || [];
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      if (!this.forceShowModal && this.content?.length <= this.slotRowCount) {
        return;
      }
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.full-content {
  overflow-y: auto;
  max-height: 60vh;
}

.more {
  color: red;
  cursor: pointer;
}
</style>
