<template>
  <div>
    <a-form :form="form" layout="inline" class="ph-20 mb-20">
      <a-form-item label="操作时间">
        <a-range-picker v-model="form.time"/>
      </a-form-item>

      <a-form-item label="岗位">
        <app-select-job v-model="form.post_id"
                        :list="postList"
                        mode="default"/>
      </a-form-item>

      <a-form-item label="操作人">
        <app-select-base v-model="form.operator_user_id"
                         :allow-clear="true"
                         :list="userList"
                         :options="{id: 'id', name: 'truename'}"
                         mode="default"
                         placeholder="请选择操作人"/>
      </a-form-item>

      <a-form-item class="full-width flex flex-end">
        <a-button type="primary" @click="onSearch">查询</a-button>
        <a-button type="primary" @click="onReset(true)">重置</a-button>
      </a-form-item>
    </a-form>

    <div class="ph-20 full-width">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="fetching"
               :scroll="{x: '100%'}"
               bordered
               row-key="rowId"
               class="table small-cell-table">
        <template slot="not_delete_user_list" slot-scope="text">
          <FullContentViewer title="未直接删除人员名单"
                             :content="text"/>
        </template>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import table from '@/mixins/table';

import {
  getDeletedPostRecord,
  getDeletedPostList,
  getDeletedPostUser,
} from './api';

const defaultForm = {
  time: [undefined, undefined],
  post_id: '',
  operator_user_id: undefined,
};

const columns = [
  {
    title: '序号',
    dataIndex: 'rowId',
    width: '100px',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '操作时间',
    dataIndex: 'created_at',
    width: '180px',
    align: 'center',
    customRender: (text) => {
      return text ? moment(text * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
    },
  },
  {
    title: '岗位',
    dataIndex: 'post_name',
    width: '160px',
    align: 'center',
  },
  {
    title: '直接删除人数',
    dataIndex: 'deleted_user_num',
    width: '100px',
    align: 'center',
  },
  {
    title: '未直接删除人员名单',
    dataIndex: 'not_delete_user_list',
    width: '280px',
    align: 'center',
    autoExpand: true,
    scopedSlots: {
      customRender: 'not_delete_user_list',
    },
  },
  {
    title: '操作人',
    dataIndex: 'operator',
    width: '100px',
    align: 'center',
  },
];

export default {
  name: 'DeletedRecord',
  mixins: [table],
  data() {
    return {
      defaultForm,
      form: { ...defaultForm },

      originColumns: columns,

      postList: [],
      userList: [],
    };
  },
  created() {
    this.getDeletedPostList();
    this.getDeletedPostUser();

    this.onSearch();
  },
  methods: {
    async getDeletedPostList() {
      const data = await getDeletedPostList();
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取岗位列表失败');
        return;
      }
      this.postList = data?.data || [];
    },
    async getDeletedPostUser() {
      const data = await getDeletedPostUser();
      if (!data || data.error_code) {
        this.$message.error(data?.message || '获取操作人列表失败');
        return;
      }
      this.userList = data?.data || [];
    },
    getData(page, pageSize) {
      this.executeGetData(async () => {
        const format = 'YYYY-MM-DD HH:mm:ss';

        const params = {
          page,
          per_page: pageSize,
          start_time: this.form.time?.[0]?.startOf('days')?.format(format) || '',
          end_time: this.form.time?.[1]?.endOf('days')?.format(format) || '',
          post_id: this.form.post_id || '',
          operator_user_id: this.form.operator_user_id || '',
        };

        const data = await getDeletedPostRecord(params);
        if (!data || data.error_code) {
          this.$message.error(data?.message || '获取列表失败');
          return Promise.reject(data);
        }
        this.data = (data?.data || []).map((i, index) => ({
          ...i,
          not_delete_user_list: i.not_delete_user_list?.split('<br>') || [],
          rowId: (page - 1) * pageSize + index + 1,
        }));

        return Promise.resolve(data);
      });
    },
  },
};
</script>
