import { stringify } from 'qs';
import { baseUrl } from '@/Config';
import store from '@/store/index';

import request from '@/utils/RequestNoLimits';
import { downloadFileExtra } from '@/utils/download';

//基础数据概览
export function getBasicStatisticsIndex (params) {
  return request({
    url: `/datacenter/admin/basicStatistics/index`,
    method: 'get',
    params,
  });
}


// 学员新增人数统计
export function getStudentIncreaseNumber (params) {
  return request({
    url: `/datacenter/admin/basicStatistics/newPopulationStatistics`,
    method: 'get',
    params,
  });
}

// 学员新增人数统计 - 导出表格
export function getStudentIncreaseNumberDownload (params) {
  let url = '/datacenter/admin/basicStatistics/newPopulationStatistics';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 平台活跃统计 - 日活统计
export function getActiveOverview (params) {
  return request({
    url: `/datacenter/admin/active/activeUserNum`,
    method: 'get',
    params,
  });
}

// 平台活跃统计 - 日活统计
export function getActiveDay (params) {
  return request({
    url: `/datacenter/admin/active/activeDayNum`,
    method: 'get',
    params,
  });
}

// 平台活跃统计 - 日活统计 - 导出表格
export function getActiveDayDownload (params) {
  let url = '/datacenter/admin/active/activeDayNum';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

// 平台活跃统计 - 月活统计
export function getActiveMonth (params) {
  return request({
    url: `/datacenter/admin/active/activeMonthNum`,
    method: 'get',
    params,
  });
}

// 平台活跃统计 - 月活统计 - 导出表格
export function getActiveMonthDownload (params) {
  let url = '/datacenter/admin/active/activeMonthNum';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

//话术中心
export function getBasicInfo (params) {
  return request({
    url: `/datacenter/admin/skillSpeechStatistics/basicInfo`,
    method: 'get',
    params,
  });
}

//下载中心
export function getDownloadStats (params) {
  return request({
    url: `/datacenter/admin/downloadStatistics/basicInfo`,
    method: 'get',
    params,
  });
}


// 内容分析
export function getContentAnalysis (params) {
  return request({
    url: `/datacenter/admin/basicStatistics/contentAnalysis`,
    method: 'get',
    params,
  });
}


// 话术中心
export function getSpeechSkillUseTimes (params) {
  return request({
    url: `/datacenter/admin/skillSpeechStatistics/getSpeechSkillUseTimes`,
    method: 'get',
    params,
  });
}

// 话术中心
export function excelSpeechSkillUseTimes (params) {
  let url = '/datacenter/admin/skillSpeechStatistics/getSpeechSkillUseTimes';
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}

export function getContentAnalysisExcel (params) {
  let url = '/datacenter/admin/basicStatistics/contentAnalysis'
  if (baseUrl[baseUrl.length - 1] === '/') {
    url = url.substring(1);
  }
  const path = `${baseUrl}${url}?${stringify(params)}`;
  return downloadFileExtra({
    url: path,
    method: 'GET',
    token: store.state.User.token || '',
  });
}





