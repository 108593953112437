const IsProd = process.env.NODE_ENV === 'production';
const IsDev = process.env.NODE_ENV !== 'production';
const UserProxy = true;
const HostUrl = 'https://benteng.test.wemark.tech/';
const BaseUrl = IsProd ? '' : (UserProxy ? '' : HostUrl);
const bdBaseUrl = 'https://apis.map.qq.com/';

module.exports = {
  label: 'bt',
  isProd: IsProd,
  isDev: IsDev,
  baseUrl: BaseUrl,
  hostUrl: HostUrl,
  userProxy: UserProxy,
  ossBaseUrl: 'https://bentengprocdn.wemark.tech/benteng-admin/',
  bdBaseUrl,
};
