const Index = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/schedule/Index');
const Gantt = () => import(/* webpackChunkName: "TrainingCenter" */  '@views/trainingCenter/schedule/GanttIndex');

const routes = [
  {
    path: 'schedule',
    component: Index,
    meta: { hideNav: true },
    children: [
      { path: '', redirect: 'gantt' },
      {
        path: 'gantt',
        component: Gantt,
      },
    ],
  },
];

export default routes;
