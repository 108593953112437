import { baseUrl } from '@/Config';
import { stringify } from 'qs';
import store from '@/store/index';
import request from '@/utils/Request';
import {downloadFileExtra} from '@/utils/download/index';

// 获取业务详情-业务信息+下拉菜单数据
export function getTrainDetailsList(params) {
  return request({
    url: '/offline/admin/apply/info',
    method: 'get',
    params
  });
}
// 获取业务详情-报名/结业统计-列表+导出
export function getTrainApplyList(params, isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/apply/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/apply/index',
    method: 'get',
    params
  });
}
// 业务详情-报名/结业统计-公布结业状态
export function postApplyGraduation(data) {
  return request({
    url: '/offline/admin/apply/graduation',
    method: 'post',
    data
  });
}
// 获取业务详情-考勤打卡
export function getTrainClockinList(params,isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/clockin/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/clockin/index',
    method: 'get',
    params
  });
}
// 获取业务详情-考勤打卡详情
export function getTrainClockinLogs(params) {
  return request({
    url: '/offline/admin/clockin/logs',
    method: 'get',
    params
  });
}
// 业务详情-考试成绩统计+导出
export function getExamList(params,isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/exam/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/exam/index',
    method: 'get',
    params
  });
}
// 业务详情-考试成绩统计+导出
export function getLiveList(params,isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/live/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/live/index',
    method: 'get',
    params
  });
}
// 业务分类列表
export function getCategories(params) {
  return request({
    url: '/offline/admin/training/categories',
    method: 'get',
    params
  });
}
// 新增/编辑业务分类
export function addCategories(data) {
  return request({
    url: '/offline/admin/training/categories/save',
    method: 'post',
    data
  });
}
// 删除业务分类
export function delCategories(data) {
  return request({
    url: '/offline/admin/training/categories/delete',
    method: 'post',
    data
  });
}
// 业务详情-报名/结业统计-变更结业状态
export function updateApplyUgs(data) {
  return request({
    url: '/offline/admin/apply/ugs',
    method: 'post',
    data
  });
}
// 业务详情-报名/结业统计-导入人员名单
export function saveImportpl(data) {
  return request({
    url: '/offline/admin/apply/importpl',
    method: 'post',
    data
  });
}
// 业务详情-报名/结业统计-导入认证成绩
export function saveImportcg(data) {
  return request({
    url: '/offline/admin/apply/importcg',
    method: 'post',
    data
  });
}
// 导入行动计划
export function postBusinessStore(data) {
  return request({
    url: '/offline/app/lecturer/business/store',
    method: 'POST',
    data,
  });
}
// 行动计划统计列表
export function getBusinessShow(params) {
  return request({
    url: '/offline/app/lecturer/business/show',
    method: 'GET',
    params,
  });
}
// 业务详情-作业统计+导出
export function getHomeworkList(params,isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/homework/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/homework/index',
    method: 'get',
    params
  });
}
// 业务详情-作业统计-获取作业标签
export function getHomeworkLabel(params) {
  return request({
    url: '/offline/admin/homework/label',
    method: 'get',
    params
  });
}
// 业务详情-作业统计-获取作业详情
export function getHomeworkdDetail(params) {
  return request({
    url: '/offline/admin/homework/detail',
    method: 'get',
    params
  });
}
// 业务详情-提交材料统计+导出
export function getMaterialList(params,isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/material/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/material/index',
    method: 'get',
    params
  });
}
// 业务详情-提交材料统计-获取材料标签
export function getMaterialLabel(params) {
  return request({
    url: '/offline/admin/material/label',
    method: 'get',
    params
  });
}
// 业务详情-提交材料统计+写评价
export function postSummary(data) {
  return request({
    url: '/offline/admin/material/summary',
    method: 'POST',
    data
  });
}
// 业务详情-行动计划统计列表+导出
export function getPlanList(params,isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/plan/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/plan/index',
    method: 'get',
    params
  });
}
// 业务详情-行动计划统计-获取行动计划标签+计划内容
export function getPlanLabel(params) {
  return request({
    url: '/offline/admin/plan/label',
    method: 'get',
    params
  });
}
// 业务详情-行动计划统计-写评价
export function postPlan(data) {
  return request({
    url: '/offline/admin/plan/summary',
    method: 'POST',
    data
  });
}
// 辅导报名审批
export function getApprovalApply(params) {
  return request({
    url: '/offline/admin/approval/apply',
    method: 'get',
    params
  });
}
// 辅导预约审批
export function getApprovalAppointment(params) {
  return request({
    url: '/offline/admin/approval/appointment',
    method: 'get',
    params
  });
}
// 审批
export function putApprovalAction(params) {
  return request({
    url: '/offline/admin/approval/action',
    method: 'put',
    params
  });
}
// 辅导业务列表
export function getApprovalList(params) {
  return request({
    url: '/offline/admin/approval/training_list',
    method: 'get',
    params
  });
}
// 业务详情-培训结果汇总列表+导出
export function getResultList(params, isDownload) {
  if (isDownload) {
    const path = `${baseUrl}/offline/admin/result/index?${stringify(params)}`;
    return downloadFileExtra({
      url: path,
      method: 'GET',
      token: store.state.User.token || '',
    });
  }
  return request({
    url: '/offline/admin/result/index',
    method: 'get',
    params
  });
}
