import { getTrainDetailsList } from '@/api/offline/Details';
import { getOfflineTemplate } from '@/api/offline/offline';

const Offline = {
  namespaced: true,
  state: {
    trainDetails: {},
    trainDetailsId: 1,
    trainDetailsMsg: '',
    trainType: undefined, // 1培训 2辅导
    isAppoint: undefined, // 0非手动指派，1手动指派
    isCertIssue: undefined, // 是否发放证书 1: 发放 0: 不发放
    stage: [], // 当前辅导，可选择的阶段
    templates: {}, // 线下培训各页面模版
  },
  mutations: {
    updateDetails(state, trainDetails) {
      state.trainDetails = trainDetails || {};
    },
    updateDetailsId(state, trainDetailsId) {
      state.trainDetailsId = trainDetailsId || 1;
    },
    updateDetailsMsg(state, trainDetailsMsg) {
      state.trainDetailsMsg = trainDetailsMsg || '';
    },
    updateTrainType(state, trainType) {
      state.trainType = trainType;
    },
    updateIsAppoint(state, isAppoint) {
      state.isAppoint = isAppoint;
    },
    updateIsCertIssue(state, isCertIssue) {
      state.isCertIssue = isCertIssue;
    },
    updateStage(state, stage) {
      state.stage = stage || [];
    },
    updateOfflineTemplate(state, templates) {
      state.templates = templates;
    },
  },
  actions: {
    async getDetails({ commit }, id) {
      const data = await getTrainDetailsList({ training_id: id });
      if (!data || data.error_code) {
        return {};
      }
      const result = data?.data || [];
      commit('updateDetails', result);
      commit('updateDetailsId', id);
      commit('updateDetailsMsg', data.message);
      return result;
    },
    async getOfflineTemplate({ commit }) {
      const data = await getOfflineTemplate();
      commit('updateOfflineTemplate', data?.data);
    },
  },
};

export default Offline;
