const Student = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/student/Index');
const Exam = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/student/Exam');
const Finish = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/student/Finish');
const Participate = () => import(/* webpackChunkName: "Statistics" */ '@views/statistics/participation/student/Participate');

const StatisticsParticipationStudentRouter = [
  {
    path: 'student',
    component: Student,
    children: [
      {
        path: '',
        redirect: 'finish',
      },
      {
        name: 'StatisticsParticipationStudentFinish',
        path: 'finish',
        component: Finish,
      },
      {
        name: 'StatisticsParticipationStudentExam',
        path: 'exam',
        component: Exam,
      },
      {
        name: 'StatisticsParticipationStudentParticipate',
        path: 'participate',
        component: Participate,
      },
    ],
  },
];

export default StatisticsParticipationStudentRouter;
