const OverviewIndex = () => import(/* webpackChunkName: "Statistics" */  '@views/certificate/overview/Index.vue');
const OverviewOffline = () => import(/* webpackChunkName: "Statistics" */  '@views/certificate/overview/Offline.vue');
const OverviewOnline = () => import(/* webpackChunkName: "Statistics" */  '@views/certificate/overview/Online.vue');

const routes = [
  {
    path: 'certificate',
    component: OverviewIndex,
    meta: { title: '证书查询' },
    children: [
      { path: '', redirect: 'online' },
      { path: 'offline', component: OverviewOffline, meta: { title: '线下培训证书' } },
      { path: 'online', component: OverviewOnline, meta: { title: '线上任务证书' } },
    ],
  },
];

export default routes;
